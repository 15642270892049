export const gatherings = {
  'aods': "AOD Sharing",
  'mi': 'Mass Indoctrination',
  'wbe': 'Worldwide Bible Exposition',
  'wbs': 'Worldwide Bible Study',
  'gph': "Global Prayer for Humanity",
  'ws': 'Worship Service',
  'pm': 'Prayer Meeting',
  'spm': 'Special Prayer Meeting',
  'tg': 'Regular Thanksgiving',
  'spbb': 'Special Thanksgiving',
  'nyr': 'Christian New Year',
  'pmws': 'Combined PM and WS',
  'ls': "Lord's Supper",
  'aodm': "AOD Meeting",
  'nbo': "Newly Baptized Orientation",
  'sbk': "Serbisyong Kapatiran",
};

export const activities = {
  'gen': 'General Meeting',
  'grd': 'Graduation',
  'prc': 'Practice',
  '247': '24x7 Destino',
  'ara': 'Aralan',
  'vmf': 'Virtual Music Festival',
};

export const gatheringTypes = {
  'replay': 'Replay',
  'live': 'Live',
};

export const remittanceMethods = {
  'mail': 'Registered Mail',
  'bank': 'Bank Transfer',
};

export const remittanceStatuses = {
  'remitted': 'Remitted',
  'received': 'Received (Closed)',
  'duplicate': 'Duplicate',
  'needs-action': 'Needs Action',
  'for-correction': 'For Correction',
  'pending': 'Pending',
  'cancelled': 'Cancelled',
};

export const roles = {
  'lsc': 'Locale Secretary',
  'llc': 'Locale Coordinator',
  'ltr': 'Locale Treasurer',
  'ntr': 'National Treasurer',
  'wrk': 'Worker',
};

export const roleKeys = {
  'ntr': 'ntr',
  'nsc': 'nsc',
  'nof': 'nof',
  'ncs': 'ncs',
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

export const dataSource = [
  {
    key: '1',
    name: 'Juan Dela Cruz',
    localeChurch: 'Kawasaki',
    churchId: '00100020',
  },
];

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Church Id',
    dataIndex: 'churchId',
    key: 'churchId',
  },
  {
    title: 'Locale',
    dataIndex: 'localeChurch',
    key: 'localeChurch',
  },
];

export const lineLink = [
  {
    key: '1',
    churchId: '00100020',
    lineLink: 'https://line.me/ti/p/gSOCj2tfjw',
  },
];

export const lineLinkColumns = [
  {
    title: 'Church Id',
    dataIndex: 'churchId',
    key: 'churchId',
  },
  {
    title: 'Line Link',
    dataIndex: 'lineLink',
    key: 'lineLink',
  },
];

export const nationalities = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Anguillan',
  'Argentine',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bermudian',
  'Bhutanese',
  'Bolivian',
  'Botswanan',
  'Brazilian',
  'British',
  'British Virgin Islander',
  'Bruneian',
  'Bulgarian',
  'Burkinan',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Cayman Islander',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Citizen of Antigua and Barbuda',
  'Citizen of Bosnia and Herzegovina',
  'Citizen of Guinea-Bissau',
  'Citizen of Kiribati',
  'Citizen of Seychelles',
  'Citizen of the Dominican Republic',
  'Citizen of Vanuatu ',
  'Colombian',
  'Comoran',
  'Congolese (Congo)',
  'Congolese (DRC)',
  'Cook Islander',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cymraes',
  'Cymro',
  'Cypriot',
  'Czech',
  'Danish',
  'Djiboutian',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirati',
  'English',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Faroese',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Gibraltarian',
  'Greek',
  'Greenlandic',
  'Grenadian',
  'Guamanian',
  'Guatemalan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Honduran',
  'Hong Konger',
  'Hungarian',
  'Icelandic',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakh',
  'Kenyan',
  'Kittitian',
  'Kosovan',
  'Kuwaiti',
  'Kyrgyz',
  'Lao',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtenstein citizen',
  'Lithuanian',
  'Luxembourger',
  'Macanese',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivian',
  'Malian',
  'Maltese',
  'Marshallese',
  'Martiniquais',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monegasque',
  'Mongolian',
  'Montenegrin',
  'Montserratian',
  'Moroccan',
  'Mosotho',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'Niuean',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Palestinian',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Pitcairn Islander',
  'Polish',
  'Portuguese',
  'Prydeinig',
  'Puerto Rican',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Salvadorean',
  'Sammarinese',
  'Samoan',
  'Sao Tomean',
  'Saudi Arabian',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Sierra Leonean',
  'Singaporean',
  'Slovak',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'South Sudanese',
  'Spanish',
  'Sri Lankan',
  'St Helenian',
  'St Lucian',
  'Stateless',
  'Sudanese',
  'Surinamese',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian',
  'Tristanian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Turks and Caicos Islander',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbek',
  'Vatican citizen',
  'Venezuelan',
  'Vietnamese',
  'Vincentian',
  'Wallisian',
  'Welsh',
  'Yemeni',
  'Zambian',
  'Zimbabwean',
];