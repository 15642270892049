import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Col, Row, Spin, Table, message,
} from 'antd';
import { RemittanceReportsFilter } from '../../filters';

import * as qs from 'query-string';
import moment from 'moment';

import financeAPI from '../../../apis/financeAPI';

import 'antd/dist/antd.css';
import './List.css';

class RemittanceReceiptsReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      churchId: '',
      startDate: '',
      endDate: '',
      filter: {},
      items: [],
      members: [],
      bankAccounts: [],
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
    };
    this.rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
    };
  }

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'Receipt No',
        dataIndex: 'receiptNumber',
        key: 'receiptNumber',
        width: 100,
        render: receiptNumber => <span>{receiptNumber}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        render: status => <span>{status}</span>,
      },
      {
        title: 'Remitter',
        dataIndex: 'remitter',
        key: 'remitter',
        width: 150,
        render: remitter => <span>{remitter ? remitter.name : null}</span>,
      },
      {
        title: 'Remitter Account Name',
        dataIndex: 'remitterAccountName',
        key: 'remitterAccountName',
        width: 150,
        render: remitterAccountName => <span>{remitterAccountName}</span>,
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    let bankAccount = {};
    if (this.props.account) {
      const query = { name: this.props.account };
      const { data } = await this.callApi(`/fin/bank_accounts?${qs.stringify(query)}`);
      if (data.length === 1) {
        bankAccount = data[0];
        this.setState({ bankAccount: bankAccount._id });
      }
    }
  }

  getItems = async (query) => {
    this.setState({ loading: true });
    const queryWithStatus = { ...query, status: this.props.status };
    const response = await financeAPI.getUrl(`/fin/remittance_reports/receipts?${qs.stringify(queryWithStatus)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await financeAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  refresh = () => {
    this.getItems({ user: this.props.userMemberId })
      .then(res => {
        this.setState({ items: res.data, loading: false })
      })
      .catch(err => console.log(err));
  }

  setFilter = async (updatedInfo) => {
    const currentInfo = this.state.filter;
    this.setState({
      filter: { ...currentInfo, ...updatedInfo }
    });
  };

  filter = () => {
    const { userMemberId } = this.props;
    const { memberId, receiptNumber, bankAccount, startDate, endDate } = this.state.filter;
    const query = {
      user: userMemberId,
      memberId, receiptNumber, startDate, endDate, bankAccount,
    };
    this.getItems(query)
      .then(res => {
        this.setState({ items: res.data, loading: false })
      })
  }

  downloadFile = async () => {
    const { selectedRowKeys } = this.state;
    const { userMemberId } = this.props;
    const { memberId, receiptNumber, bankAccount, status, startDate, endDate } = this.state.filter;
    const query = {
      user: userMemberId,
      memberId, receiptNumber, startDate, endDate, bankAccount,
      status,
    };
    console.log('query', query)

    financeAPI.fetchUrl(`/fin/remittance_reports/receipts?${qs.stringify(query)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({ _ids: selectedRowKeys }),
    }).then(async res => {
      if (res.status === 200) {
        const body = await res.json();

        let outputTxt = "";
        outputTxt = "Receipt Number,Remitter Name,Remitter Account Name\r\n";
        body.data.forEach(item => {
          outputTxt = outputTxt + [
            item.receiptNumber,
            '"' + item.remitter.name + '"',
            '"' + item.remitterAccountName + '"',
          ].join(",") + "\r\n";
        });

        const element = document.createElement("a");
        const file = new Blob(["\ufeff"+outputTxt], { type: ' type: "text/csv;charset=UTF-8"' });
        element.href = URL.createObjectURL(file);

        // Save the Data
        const fileName = `Remittances_${moment().format('YYYYMMDD_hhmmss')}.csv`;
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error submitting.');
    });
  }

  render() {
    this.createTableColumns();
    const { items, loading, selectedRowKeys } = this.state;
    let modResult = [];
    let i = 0;
    items.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Remittance Receipts Report Page</h3>
            </Col>
          </Row>
          <RemittanceReportsFilter
            setFilter={this.setFilter}
            handleFilter={this.filter}
            downloadFile={this.downloadFile}
            refresh={this.refresh}
            data={selectedRowKeys}
            downloadText={"Download CSV"}
            bankAccount={this.state.bankAccount}
            remitMethod={this.props.remitMethod}
          />
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={20}>
              {(items.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no remittances.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the remittances queried:`}</h3>
                  <Table
                    rowSelection={this.rowSelection}
                    columns={this.columns}
                    dataSource={modResult}
                    scroll={{ x: 1000 }}
                    pagination={false}
                  />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(RemittanceReceiptsReport);
