import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Col, Row, message,
} from 'antd';
import { ForActionReportFilter } from '../../filters';

import * as qs from 'query-string';
import moment from 'moment';

import financeAPI from '../../../apis/financeAPI';

import 'antd/dist/antd.css';
import './List.css';

class RemittanceForActionReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        status: 'needs-action',
      },
      items: [],
      members: [],
      bankAccounts: [],
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    let bankAccount = {};
    if (this.props.account) {
      const query = { name: this.props.account };
      const { data } = await this.callApi(`/fin/bank_accounts?${qs.stringify(query)}`);
      if (data.length === 1) {
        bankAccount = data[0];
        this.setState({ bankAccount: bankAccount._id });
      }
    }
  }

  getItems = async (query) => {
    this.setState({ loading: true });
    const queryWithStatus = { ...query, status: this.props.status };
    const response = await financeAPI.getUrl(`/fin/remittance_reports/receipts?${qs.stringify(queryWithStatus)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await financeAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  setFilter = async (updatedInfo) => {
    const currentInfo = this.state.filter;
    this.setState({
      filter: { ...currentInfo, ...updatedInfo }
    });
  };

  downloadFile = async () => {
    const { userMemberId } = this.props;
    const { memberId, receiptNumber, bankAccount, status, startDate, endDate } = this.state.filter;
    const query = {
      user: userMemberId,
      memberId, receiptNumber, startDate, endDate, bankAccount,
      status,
    };
    console.log('query', query)

    financeAPI.fetchUrl(`/fin/remittance_reports/for_action?${qs.stringify(query)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({ userMemberId }),
    }).then(async res => {
      if (res.status === 200) {
        const body = await res.json();

        let outputTxt = "";
        //outputTxt = "Receipt Number,Remittance Date,Remitter Name,Remitter Account Name,Remarks/Breakdown\r\n";
        outputTxt = [
          "Receipt Number",
          "Status",
          "Remittance Date",
          "Remitter Name",
          "Remitter Account Name",
          "Breakdown",
          "Remakrs",
        ].join(",") + "\r\n";
        body.data.forEach(item => {
          const {
            receiptNumber, status,
            remittanceDate, remitter, remitterAccountName,
            remarks, notes,
          } = item;
          outputTxt = outputTxt + [
            receiptNumber,
            status,
            '"' + (remittanceDate ? moment(remittanceDate).format('YYYY/MM/DD') : "") + '"',
            '"' + (remitter.name ? remitter.name : "") + '"',
            '"' + (remitterAccountName ? remitterAccountName : "") + '"',
            '"' + (notes ? notes : "") + '"',
            '"' + (remarks ? remarks : "") + '"',
          ].join(",") + "\r\n";
        });

        const element = document.createElement("a");
        const file = new Blob(["\ufeff"+outputTxt], { type: ' type: "text/csv;charset=UTF-8"' });
        element.href = URL.createObjectURL(file);

        // Save the Data
        const fileName = `Remittances_${moment().format('YYYYMMDD_hhmmss')}.csv`;
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error submitting.');
    });
  }

  render() {
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Needs Action Report Page</h3>
            </Col>
          </Row>
          <ForActionReportFilter
            filter={this.state.filter}
            setFilter={this.setFilter}
            downloadFile={this.downloadFile}
            downloadText={"Download CSV"}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(RemittanceForActionReport);
