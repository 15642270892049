import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Button, Form, Select, Row, Col, PageHeader, Spin, DatePicker, Icon,
  message, Input, Modal, Upload,
} from 'antd';
import ReactGA from 'react-ga';
import RemitMethod from './RemitMethod';

import querystring from 'querystring';
import moment from 'moment';
import _ from 'lodash';
import * as functions from '../../../helpers/functions';
import * as constants from '../../../helpers/constants';

import amsAPI from '../../../apis/amsAPI';
import financeAPI from '../../../apis/financeAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    lg: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class EditRemittance extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      receiptFileList: [],
      transferFileList: [],
      remitters: [],
      recipients: [],
      bankAccounts: [],
      record: {},
      remitImg: {},
      receiptImg: {},
      transferImgs: [],
      submitting: false,
      loadingRecord: true,
      loadingRemitImg: true,
      loadingReceiptImg: true,
      loadingTransferImgs: true,
      previewVisible: false,
      previewImage: '',
      prevStatus: '',
      rotation: 0,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true, loadingBankAccounts: true });
    this.getBankAccounts()
      .then(res => {
        this.setState({ bankAccounts: res.data, loadingBankAccounts: false });
      })
      .catch(err => console.log(err));
    this.getRecord()
      .then(res => {
        this.setState({ record: res.data, loadingRecord: false, prevStatus: res.data.status });
      })
      this.getRecordImage('remitImg')
      .then(res => {
        this.setState({ remitImg: res.data ? res.data.remitImg : {}, loadingRemitImg: false });
      })
    this.getRecordImage('receiptImg')
      .then(res => {
        this.setState({ receiptImg: res.data ? res.data.receiptImg : {}, loadingReceiptImg: false });
      })
    this.getRecordImage('transferImgs')
      .then(res => {
        this.setState({ transferImgs: res.data ? res.data.transferImgs : [], loadingTransferImgs: false });
      })
  }

  getBankAccounts = async () => {
    const response = await financeAPI.getUrl(`/fin/bank_accounts`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getRecord = async () => {
    const { _id } = this.props.match.params;
    const response = await financeAPI.getUrl(`/fin/remittances/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getRecordImage = async (image) => {
    const { _id } = this.props.match.params;
    const response = await financeAPI.getUrl(`/fin/remittances/${_id}/img?image=${image}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSearchRemitter = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ remitters: data })
      });
    } else {
      this.setState({ remitters: [] });
    }
  };

  handleSearchRecipient = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ recipients: data })
      });
    } else {
      this.setState({ recipients: [] });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    const base64Flag = `data:${file.contentType};base64,`;
    const imageStr = functions.arrayBufferToBase64(file.data.data);
    const image = base64Flag + imageStr;

    this.setState({
      previewImage: image,
      previewVisible: true,
    });
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'update remittance'
    });

    e.preventDefault();
    const { _id } = this.props.match.params;
    this.setState({ submitting: true });
    const {
      remitter, remitMethod, bankAccount, recipient, remittanceDate, receiptDate,
      status, notes, remitterEmail, remitterContactNum, remitterAccountName, remarks,
      receiptNumber, gfReceiptNumber,
    } = this.state.record;

    const { fileList, receiptFileList, transferFileList, prevStatus } = this.state;
    const formData = new FormData();

    formData.append('receiptNumber', receiptNumber);
    formData.append('remitter', remitter);
    formData.append('remitMethod', remitMethod);
    formData.append('remittanceDate', moment(remittanceDate).format());
    formData.append('status', status);
    formData.append('notes', notes);
    if (remarks) formData.append('remarks', remarks);
    if (gfReceiptNumber) formData.append('gfReceiptNumber', gfReceiptNumber);
    formData.append('remitterEmail', remitterEmail);
    formData.append('remitterContactNum', remitterContactNum);
    formData.append('remitterAccountName', remitterAccountName);
    if (fileList[0]) formData.append('remitImg', fileList[0]);

    transferFileList.forEach(transferImg => {
      formData.append('transferImgs[]', transferImg);
    });

    if (remitMethod === "mail") {
      formData.append('recipient', recipient._id);
    } else {
      formData.append(
        'bankAccount',
        typeof bankAccount === "object" ? bankAccount._id : bankAccount
      );
    }
    if (receiptDate) formData.append('receiptDate', moment(receiptDate).format());
    if (receiptFileList[0]) formData.append('receiptImg', receiptFileList[0]);
    formData.append('userMemberId', this.props.userInfo.userMemberId);

    financeAPI.fetchMulter(`/fin/remittances/${_id}/officer`, {
      method: 'PATCH',
      mode: 'cors',
      credentials: 'include',
      body: formData,
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Remittance successfully submitted.');
          this.setState({ submitting: false });
          const toEditLink = (prevStatus !== status && status === "for-correction");
          if (toEditLink) {
            this.props.history.push(`/remittances/${_id}/generate`);
          } else {
            this.props.history.push(`/remittances`);
          }
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting remittance.');
    });
  };

  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if(newRotation === 360){
      newRotation = 0;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  render() {
    const {
      record, recipients, remitters, bankAccounts,
      loadingRecord, loadingBankAccounts, loadingRemitImg, loadingReceiptImg, loadingTransferImgs,
      previewVisible, previewImage, remitImg, receiptImg, receiptFileList, transferImgs, transferFileList,
      rotation,
    } = this.state;
    const {
      receiptNumber, gfReceiptNumber, remitter, recipient, bankAccount,
      remitterContactNum, remitterEmail,
      remittanceDate, receiptDate, notes, status, remarks,
      updatedBy, updatedAt,
    } = record;
    const disableSubmit = !remitter
      || (!recipient && !bankAccount)
      || !remittanceDate
      || (status === "received"
          && (receiptFileList.length === 0 && _.isEmpty(receiptImg))
         );
    const loading =  loadingRecord || loadingBankAccounts;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.receiptFileList.indexOf(file);
          const newFileList = state.receiptFileList.slice();
          newFileList.splice(index, 1);
          return {
            receiptFileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          receiptFileList: [file],
        }));
        return false;
      },
      fileList: receiptFileList,
      accept: "image/*",
    };

    const transferProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.transferFileList.indexOf(file);
          const newFileList = state.transferFileList.slice();
          newFileList.splice(index, 1);
          return {
            transferFileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          transferFileList: [...state.transferFileList, file],
        }));
        return false;
      },
      fileList: transferFileList,
      accept: "image/*",
    };

    const remittanceStatuses = Object.entries(constants.remittanceStatuses);
    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label={"Receipt Number"}>
                    <Input
                      defaultValue={receiptNumber}
                      onChange={(e) => this.updateRecord({ receiptNumber: e.target.value})}
                    />
                  </Form.Item>
                  {(!_.isEmpty(bankAccount) &&
                      (
                        bankAccount.name.toLowerCase().indexOf('international') >= 0
                        || bankAccount.name.toLowerCase().indexOf('national') >= 0
                        || bankAccount.name.toLowerCase().indexOf('kapi') >= 0
                        || bankAccount.name.toLowerCase().indexOf('other') >= 0
                      )
                     ) &&
                    <Form.Item label={"GF Receipt Number"}>
                      <Input
                        onChange={(e) => this.updateRecord({ gfReceiptNumber: e.target.value })}
                        defaultValue={gfReceiptNumber ? gfReceiptNumber : null}
                      />
                    </Form.Item>
                  }
                  <Form.Item label="Remitter">
                    <Select
                        showSearch
                        dropdownMatchSelectWidth={false}
                        mode={this.state.mode}
                        optionFilterProp="value"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.handleSearchRemitter}
                        onChange={(value) => this.updateRecord({remitter: value})}
                        notFoundContent={null}
                        defaultValue={remitter ? remitter.name : null}
                        disabled={true}
                      >
                        {remitters.map(item => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {`${item.churchId} ${item.name}`}
                            </Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item label={"Locale Church"}>
                    <Input
                      defaultValue={remitter.localeChurchId ? remitter.localeChurchId.name : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Contact Number"}>
                    <Input
                      onChange={(e) => this.updateRecord({ remitterContactNum: e.target.value })}
                      defaultValue={remitterContactNum ? remitterContactNum : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Contact Email"}>
                    <Input
                      onChange={(e) => this.updateRecord({ remitterEmail: e.target.value })}
                      defaultValue={remitterEmail ? remitterEmail : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <RemitMethod
                    handleSearchRecipient={this.handleSearchRecipient}
                    updateRecord={this.updateRecord}
                    data={record}
                    recipients={recipients}
                    bankAccounts={bankAccounts}
                    disabled={true}
                  />
                  <Form.Item label={"Remittance Date"}>
                    <DatePicker
                      placeholder={null}
                      onChange={(value) => this.updateRecord({ remittanceDate: value })}
                      defaultValue={remittanceDate ? moment(remittanceDate) : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Receipt Date"}>
                    <DatePicker
                      placeholder={null}
                      onChange={(value) => this.updateRecord({ receiptDate: value })}
                      defaultValue={receiptDate ? moment(receiptDate) : null}
                    />
                  </Form.Item>
                  <Form.Item label={"Status"}>
                    <Select
                      placeholder={status ? null : "Status"}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => this.updateRecord({ status: value })}
                      defaultValue={status ? status : null}
                    >
                      {remittanceStatuses.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Breakdown/Notes:">
                    <TextArea
                      autosize={{ minRows: 7 }}
                      onChange={(e) => this.updateRecord({ notes: e.target.value })}
                      defaultValue={notes ? notes : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label="Remarks:">
                    <TextArea
                      autosize={{ minRows: 7 }}
                      onChange={(e) => this.updateRecord({ remarks: e.target.value })}
                      defaultValue={remarks ? remarks : null}
                    />
                  </Form.Item>
                  <Form.Item label={"Receipt (receiver/passbook)"}>
                    <Upload {...props}>
                      <Button>
                        <Icon type="upload" /> Select File
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item label={"Receipt (transfer/action item)"}>
                    <Upload {...transferProps}>
                      <Button>
                        <Icon type="upload" /> Select File
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item label="Receipts">
                    <Button
                      onClick={(e) => this.handlePreview(remitImg)}
                      disabled={_.isEmpty(remitImg)}
                      loading={loadingRemitImg}
                    >
                      <Icon type="eye"/>Preview receipt (remitter)
                    </Button>
                    <Button
                      onClick={(e) => this.handlePreview(receiptImg)}
                      disabled={_.isEmpty(receiptImg)}
                      loading={loadingReceiptImg}
                    >
                      <Icon type="eye"/>Preview receipt (receiver/passbook)
                    </Button>
                    <div>
                      {transferImgs && transferImgs.map(transferImg => {
                        return (
                          <Button
                            icon="eye"
                            onClick={(e) => this.handlePreview(transferImg)}
                            disabled={_.isEmpty(transferImg)}
                            loading={loadingTransferImgs}
                          >
                            Preview receipt (transfer/action item)
                          </Button>
                        )
                      })
                      }
                    </div>
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon="redo"
                        onClick={this.rotate}
                      />
                      <img
                        alt="example"
                        style={{ width: '100%', transform: `rotate(${rotation}deg)` }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                  <Form.Item label={"Last Updated By"}>
                    <Input
                      defaultValue={updatedBy ? updatedBy.name : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item label={"Last Updated At"}>
                    <Input
                      defaultValue={updatedAt ? moment(updatedAt).format("YYYY/MM/DD hh:mm:ss") : null}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                      disabled={disableSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(EditRemittance);
