import React, { Component } from 'react';
import { PageHeader, Row, Col, Icon, Card } from 'antd';
import { NavLink } from 'react-router-dom';

import 'antd/dist/antd.css';
import './Home.css';

class AdminHome extends Component {

  render() {
    const { roles } = this.props.userInfo;
    const isDisplayAms = roles.reduce((total, item) => ['nsc','ncs'].includes(item) ? total+1 : total, 0);
    const displayFinReports = roles.reduce((total, item) => ['ntr' ].includes(item) ? total+1 : total, 0);

    const name = localStorage.getItem('name') ? localStorage.getItem('name') : "Guest";
    return (
      <PageHeader
        title={`Welcome ${name}, what would you like to administer today?`}
      >
        <div className="wrap">
          <div className="extraContent">
            <Row gutter={16} style={{margin: "10px"}}>
              {displayFinReports ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/finance">
                      <Icon type="account-book" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              {isDisplayAms ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/reports/by_gathering">
                      <Icon type="table" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              {isDisplayAms ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/reports/by_date_range">
                      <Icon type="calendar" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
              {isDisplayAms ?
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <NavLink to="/reports/by_activity">
                      <Icon type="crown" style={{ fontSize: '4em', color: '#08c' }}/>
                    </NavLink>
                  </Card>
                </Col>
                :
                null
              }
            </Row>
          </div>
        </div>
      </PageHeader>

    );
  }
}

export default AdminHome;
