import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Route } from 'react-router-dom';
import { withRouter } from "react-router";
import { Button, Col, Layout, Row, Menu, Icon, message } from 'antd';

import ReactGA from 'react-ga';
import { GoogleLogout, GoogleLogin } from 'react-google-login';

import { Login } from './login';
import {
  Home, FinanceHome, RemittancesHome, RemittanceReportsHome,
  ReportsHomeByGathering, ReportsHomeByActivity, ReportsHomeByDateRange,
} from './homes';
import {
  NewRemittance, EditRemittance, Remittances, ShowRemittance, RemittanceHistory,
} from './finance/remittance';
import {
  RemittanceReceiptsReport, RemittanceForActionReport,
} from './finance/reports';
import {
  AttendanceReportByGathering, AttendanceReportByActivity, AttendanceReportByDateRange,
} from './reports';
import { GenerateToken } from './security';
import { Unauthorized } from './errors';

import amsAPI from '../apis/amsAPI';
import * as constants from '../helpers/constants';

import 'antd/dist/antd.css';
import './ContentWrapper.css';

ReactGA.initialize('UA-146791855-1');

const { Header, Content } = Layout;

class NationalWrapper extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props){
    super(props);
    this.state = {
      isLocaleMembersPage: false,
      isSignedIn: false,
      isGoogleLogin: false,
    };
    this.goBack = this.goBack.bind(this);
    this.goToNew = this.goToNew.bind(this);
    this.goHome = this.goHome.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
    this.setLogin = this.setLogin.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  goToNew() {
    const pathname = this.props.location.pathname;
    const newUrl = pathname + '/new';
    this.props.history.push(newUrl);
  }

  goHome() {
    this.props.history.push("/");
  }

  onLoginSuccess = info => {
    amsAPI.fetchUrl('/ams/login/officer', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        email: info.profileObj.email,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const body = await res.json();
        const { _id, localeChurchId, name, isAdmin, roles } = body;
        if (
          !(roles.includes(constants.roleKeys.ntr) || roles.includes(constants.roleKeys.nsc)
            || roles.includes(constants.roleKeys.nof)|| roles.includes(constants.roleKeys.ncs))
        ) {
          const error = new Error('Not authorized.');
          throw error;
        }
        message.success("Successfully logged in.");
        this.setState({
          isSignedIn: true,
          isGoogleLogin: true,
          userInfo: {
            localeChurchId: localeChurchId,
            memberId: _id,
            roles: roles,
            name: name,
            isAdmin: isAdmin,
            userMemberId: _id,
          },
          err: null
        })
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.log(err);
      message.error('Error logging in.');
    });
  }

  setLogin = (info) => {
    this.setState({
      ...info,
      isSignedIn: true,
      err: null
    });
  }

  logoutUser = () => {
    this.setState({
      isSignedIn: false,
      isGoogleLogin: false,
    });
  }

  onLoginFailed = (err) => {
    console.log('login failed', err)
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  onLogoutSuccess = () => {
    this.setState({
      isSignedIn: false,
    })
  }

  onLogoutFailed = (err) => {
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  render() {
    const pathname = this.props.location.pathname;
    const { isSignedIn, isGoogleLogin } = this.state;
    const isAdd = pathname.indexOf('new') >= 0;
    const isListPage = pathname.substring(1).match(
        /^(remittances)$/,
      );
    const displayAddButton = isListPage;
    const remittanceStatuses = Object.keys(constants.remittanceStatuses);
    const auditRemittanceStatuses = ["duplicate", "received", "cancelled"];
    const openRemitanceStatuses = remittanceStatuses.filter(item => !auditRemittanceStatuses.includes(item));

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
              <Menu
                selectable={false}
                theme="dark"
                mode="horizontal"
                style={{ lineHeight: '20px' }}
              >
              <Menu.Item
                key="2"
                style={{ padding: '0' }}
              >
                {(pathname !== "/") &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goHome}
                  >
                    <Icon type="home" />
                  </Button>
                }
                {(pathname !== "/" && isSignedIn) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goBack}
                    style={{ marginLeft: "5px" }}
                  >
                    Back
                  </Button>
                }
              </Menu.Item>
            </Menu>
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="1"
              >
                {isSignedIn &&
                  <Button
                    type="default"
                    size="large"
                    disabled={isAdd}
                    onClick={this.goToNew}
                    style={
                      {
                        display: (displayAddButton) ? "inherit" : "none",
                        marginRight: '10px',
                      }
                    }
                  >Add</Button>
                }
                {(!isSignedIn && pathname !== "/") &&
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Login"
                    onSuccess={this.onLoginSuccess}
                    onFailure={this.onLoginFailed}
                    cookiePolicy={'single_host_origin'}
                  />
                }
                {(isSignedIn && isGoogleLogin) &&
                  <GoogleLogout
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Logout"
                    onLogoutSuccess={this.onLogoutSuccess}
                    onFailure={this.onLogoutFailed}
                  />
                }
                {(isSignedIn && !isGoogleLogin) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.logoutUser}
                  >Logout</Button>
                }
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', marginTop: 75 }}>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ padding: 24, background: '#fff', minHeight: 320 }}>
                  <Route exact path="/"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Home
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                      :
                        <Login
                          {...props}
                          setLogin={this.setLogin}
                          onLoginSuccess={this.onLoginSuccess}
                          onLoginFailed={this.onLoginFailed}
                        />
                    }
                  />

                  <Route exact path="/reports/by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ReportsHomeByGathering
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/by_date_range"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ReportsHomeByDateRange
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/by_activity"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHomeByActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_activity"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReportByActivity {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AttendanceReportByGathering
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_date_range"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <AttendanceReportByDateRange
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/finance"
                    render={(props) =>
                      this.state.isSignedIn ? <FinanceHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittancesHome
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          pathname={"/remittances"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/international"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"international"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/old_national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"old national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/old_kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          account={"old kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/mail"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          remitMethod={"mail"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/pickup"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          remitMethod={"worker-pickup"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {openRemitanceStatuses}
                          isAudit={false}
                          isLocaleChurchAccount={true}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittancesHome
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          pathname={"/remittances_closed"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/international"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"international"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/old_national"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"old national"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/old_kapi"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          account={"old kapi"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          isLocaleChurchAccount={true}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/mail"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          remitMethod={"mail"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances_closed/pickup"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Remittances
                          {...props}
                          userInfo={this.state.userInfo}
                          status = {auditRemittanceStatuses}
                          isAudit={true}
                          remitMethod={"worker-pickup"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewRemittance
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditRemittance
                          {...props}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/show"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <ShowRemittance
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/generate"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GenerateToken
                          {...props}
                          model={"remittance"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittances/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceHistory
                          {...props}
                          model={"remittance"}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceReportsHome
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports/receipts"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceReceiptsReport
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/remittance_reports/for_action"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <RemittanceForActionReport
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(NationalWrapper);
